import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/SimpleFiveColumn.js";
import heroScreenshotImageSrc from "images/app-mockup.png";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import prototypeIllustrationImageSrc from "images/server-illustration-2.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <Features
        subheading={<Subheading>Features</Subheading>}
        heading={
          <>
            We have Amazing <HighlightedText>Service.</HighlightedText>
          </>
        }
        description="As a mobile app development company, we have
        a wide range of services that will help your business."
      />
      <MainFeature
        subheading={<Subheading>Quality Work</Subheading>}
        imageSrc={heroScreenshotImageSrc}
        imageBorder={false}
        imageDecoratorBlob={true}
        description="We specialize in developing serverless or custom cloud applications, iPhone apps and android mobile apps using technologies like (but NOT limited to) PHP, Laravel, .NET, C#, Swift, Kotlin, Java, React Native, AngularJS, VueJS, NodeJS, C++ & Python."
        primaryButtonUrl="/components/innerPages/AboutUsPage"
      />
      <FeatureWithSteps
        subheading={<Subheading>STEPS</Subheading>}
        heading={
          <>
            Easy to <HighlightedText>Get Started.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      <MainFeature2
        subheading={<Subheading>VALUES</Subheading>}
        heading={
          <>
            We Always Abide by Our{" "}
            <HighlightedText>Principles.</HighlightedText>
          </>
        }
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: MoneyIcon,
            title: "Affordable",
            description:
              "We promise to offer you the best rate we can - at par with the industry standard.",
            iconContainerCss: tw`bg-green-300 text-green-800`,
          },
          {
            Icon: BriefcaseIcon,
            title: "Professionalism",
            description:
              "We assure you that our templates are designed and created by professional developers and designers.",
            iconContainerCss: tw`bg-green-300 text-green-800`,
          },
        ]}
        description="Being “good enough” doesn’t get you
        anywhere in life. We came here because we
        want to be the best."
        primaryButtonUrl="/components/innerPages/AboutUsPage"
      />
      <Pricing
        subheading={<Subheading>Pricing</Subheading>}
        heading={
          <>
            Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
          </>
        }
        plans={[
          {
            name: "Personal",
            price: "$199.99",
            duration: "Monthly",
            mainFeature: "For Individuals",
            features: ["1 Apps", "Basic Assistance"],
          },
          {
            name: "Business",
            price: "$999.99",
            duration: "Monthly",
            mainFeature: "For Small Businesses",
            features: ["2 Apps", "Push Notifications", "Priority Assistance"],
            featured: true,
          },
          {
            name: "Enterprise",
            price: "$9999.99",
            duration: "Monthly",
            mainFeature: "For Large Companies",
            features: [
              "5+ Apps",
              "Push Notifications",
              "Ad Tracking and Delivery",
              "Personal Assistance",
            ],
          },
        ]}
        description="Even the most groundbreaking app ideas need careful planning and strategic implementation. Together with you, we’ll jump into your industry, brainstorm and mind map your idea to develop it into a one of a kind app."
      />
      <Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        heading={
          <>
            Our Clients <HighlightedText>Love Us.</HighlightedText>
          </>
        }
        description="We don’t just develop apps. We build
        dreams, and our clients agree."
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
            heading: "Amazing User Experience",
            quote:
              "We could not have accomplished this groundbreaking technology without the work of our partners at Reyes Cyber Solutions. They helped take our concept and build it into a world class iPhone application, treating our project as their own. They also designed and developed our website, which is very appealing and great. When we were making the decision on which app company would help us build our apps, we were looking for an organization that would have the experience, scale to grow with us, as well as have access to world class talent. Further, we wanted people who understood our project and would collaborate closely with our design team on a regular basis, to avoid missteps and lost productivity. The team was the right choice!",
            customerName: "Charlotte Hale",
            customerTitle: "Director, Delos Inc.",
          },
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
            heading: "Love the Developer Experience and Design Principles !",
            quote:
              "Our goal was to produce a mobile app that was usable for iOS and Android.  It is a social engagement app as well as a mobile billboard for certain retail businesses. We did some background research. We wanted an expert team with background in social networking apps. That was important to us. We interviewed a several companies and decided on Reyes Cyber Solutions. I’m satisfied with their work. They’ve been a pleasure to work with.",
            customerName: "Adam Cuppy",
            customerTitle: "Founder, EventsNYC",
          },
        ]}
      />
      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "Are all the templates easily customizable ?",
            answer:
              "Yes, they all are.",
          },
          {
            question:
              "How long do you usually support an standalone app for?",
            answer:
              "We support and maintain our apps for as long as you are subscribed.",
          },
          {
            question: "What kind of payment methods do you accept ?",
            answer:
              "We accept all credit cards and bank deposits.",
          },
          {
            question:
              "Is there a subscribption service to get the latest templates and apps?",
            answer:
              "Yes, please check our services for more details.",
          },
          {
            question: "Are the templates compatible with the React?",
            answer:
              "It depends. Only if the apps and templates are made with react. Please talk to us about React solutions we can offer you for any project!",
          },
        ]}
      />
      <GetStarted />
      <Footer />
    </AnimationRevealPage>
  );
};
