import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>About Treact</Subheading>}
        heading="We are a modern custom software & app development agency."
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        primaryButtonUrl="/components/innerPages/ContactUsPage"
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
        description="We specialize in developing serverless or custom cloud applications, iPhone apps and android mobile apps using technologies like (but NOT limited to) PHP, Laravel, .NET, C#, Swift, Kotlin, Java, React Native, AngularJS, VueJS, NodeJS, C++ & Python."
      />
      <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading="We aim to disrupt the desgin space."
        buttonRounded={false}
        primaryButtonText="Contact Us"
        primaryButtonUrl="/components/innerPages/ContactUsPage"
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
        description="The best work is done when everyone works together. Whether it’s an opinion of a designer, developer, or our client - the more we know & consider, the better the product will be."
      />
      <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="We follow these."
        description="Creating successful apps is what we live for and what we absolutely love doing."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "24/7 Support",
            description: "We pride ourselves in supporting our clients through all obstacles."
          },
          {
            imageSrc: ShieldIconImage,
            title: "Strong Teams",
            description: "We strictly only deal with developers that provide top notch implementations."
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            description: "We provide you support and guarantee the reliability of our applications."
          },
        ]}
        linkText=""
      />
      {/* <TeamCardGrid 
        subheading={<Subheading>Our Team</Subheading>}
      /> */}
      <Footer />
    </AnimationRevealPage>
  );
};
